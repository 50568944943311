<template>
  <div></div>
</template>

<script>
export default {
  name: 'ComputopSuccess',
  mounted() {
    if (typeof window !== 'undefined') {
      window.parent.postMessage({
        type: 'COMPUTOP__PROCEED',
      });
    }
  },
};
</script>

<style scoped lang="scss">
</style>
